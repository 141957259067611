import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BrandsFilter from "../components/brandsFilter"
import SizesFilter from "../components/sizesFilter"
import TypesFilter from "../components/typesFilter"
import ListSearchWipe from "../components/listSearchWipe"
import {useStaticQuery, graphql } from "gatsby"
import { ShopNameTag, CanonicalForShop, CanonicalForOption} from '../utils/common'
import Helmet from "react-helmet"


const IndexWipePage = ({ location, pageContext: { type_id, size_id, brand_id, shop_id} }) => {
  const tempData  = useStaticQuery(graphql`
    query IndexWipePageQuery {
      allTypesYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allSizesYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allBrandsYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
    }
  `)


	let canonical = CanonicalForOption(CanonicalForShop(location.pathname))

  const sizeStr = ((type_id !== 'wipe') && (type_id !== 'handwipe'))? 'サイズ' : 'タイプ' 
	const typeDescription = getTypeDescription(tempData, type_id)
	const sizeDescription = getSizeDescription(tempData, size_id)
	const brandDescription = brand_id !== 'all' ? getBrandDescription(tempData, brand_id) + ' ' : ''
	let title = `${ShopNameTag(shop_id)}${brandDescription}${typeDescription} ${sizeDescription}${sizeStr}`
	let description = `${ShopNameTag(shop_id)}${brandDescription}${typeDescription} ${sizeDescription}${sizeStr}で今一番安いおしりふきがすぐ分かります！クーポン割引、ポイント還元、税込、送料込みで1枚あたりの価格を徹底比較！`
	return (
		<Layout>
  	  <Seo title={title} description={description} canonical={canonical}/>
			<ListSearchWipe typeValue={type_id} sizeValue={size_id} brandValue={brand_id} shopValue={shop_id} articleUrl={`https://hula-la.com${canonical}`} articleTitle={title} />
			<TypesFilter types={['wipe','handwipe']}/>
			<SizesFilter types={['wipe','handwipe']}/>
			<BrandsFilter types={['wipe','handwipe']}/>
			<div className="container" style={{padding: `1.5rem 1rem`}}>
				<h3 className="title is-5">オムツは一体どこが一番安いの！？</h3>
				<p>『オムツをネットで購入したいけど、どこで購入するのが一番安いの！？』こんな疑問を解決する為にこのサイトを立ち上げました。ネット通販でオムツを購入できる事はとても助かるのですが、欲しいオムツを1番安く購入できるところを探すのにとても苦労しました。もちろん、通販サイトの機能を利用して、検索キーワードで欲しいオムツを絞り込み、安い価格順に並べることはできます。しかし、オムツの販売は1パックあたりの枚数が違ったり、複数パックの場合や○枚増量など、お店ごとにオムツ全部の枚数が異なる為、単純に価格を比較でません。</p>
			</div>
			<div className="container" style={{padding: `1.5rem 1rem`}}>
				<h3 className="title is-5">安いオムツを探すには1枚あたりの価格で比較！！</h3>
				<p>安いオムツを探すには、お店毎に違うオムツの枚数と価格の関係を、同じ基準で比較しなければなりません。そこで一番良いのがオムツ1枚あたりの価格で比較する方法です。1枚あたりの価格がわかれば、他のお店を比較して安いのか高いのか判断ができます。一見すると、複数パックまとめて販売しているので安く感じるオムツでも、実はそんなに安くないというのも簡単にわかります。</p>
			</div>
			<div className="container" style={{padding: `1.5rem 1rem`}}>
				<h3 className="title is-5">1枚あたりの価格が安いものを提供！！</h3>
				<p>オムツ1枚あたりの価格で比較するのがいいと分かっていても、１つ１つを電卓で計算していたら、日が暮れてしまします。そこで、主要な通販サイトである楽天、Amazon Yahoo!ショッピング PayPayモール LOHACO ベビーザらス アカチャンホンポ イオン(AEON) auPAYマーケット 西松屋公式ストアで販売しているもので、1枚あたりの価格が安いものを日々自動的に調べてこのサイトで提供します。商品をきちんと特定する為に１つ１つの商品に割り当てられているJANコードを基に検索し、オムツ全部の枚数と税込価格から1枚あたりの価格を計算しています。</p>
			</div>
			<div className="container" style={{padding: `1.5rem 1rem`}}>
				<h3 className="title is-5">安いオムツを早く楽に手に入れよう！！</h3>
				<p>オムツはどこのお店で購入しても商品自体変わらないので少しでも安く購入したいものです。大型のショッピングモールなどを利用したり、スーパーのセール品で購入すれば安く購入する事はできますが、お子さんがいる時に外出して購入するのはとても難しいと思います。また、いろんな通販サイトを調べ回って安いお店を探すのもとても時間がかかります。ですので、是非このサイトで、できるだけ手間をかけずに、少しでも安くオムツを購入することができるのにお役に立てればと思っています。</p>
			</div>
		</Layout>			
  )
}

export default IndexWipePage

function getTypeDescription(tempData, cid){
  const ret = tempData.allTypesYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}

function getSizeDescription(tempData, cid){
  const ret = tempData.allSizesYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}

function getBrandDescription(tempData, cid){
  const ret = tempData.allBrandsYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}
