import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ListSearchPremiumWipe from "../components/listSearchPremiumWipe"
import {useStaticQuery, graphql } from "gatsby"
import { ShopNameTag, CanonicalForShop, CanonicalForOption} from '../utils/common'
import Helmet from "react-helmet"


const PremiumWipePage = ({ location, pageContext: { type_id, size_id, brand_id, shop_id} }) => {
  const tempData  = useStaticQuery(graphql`
    query PremiumWipePageQuery {
      allTypesYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allSizesYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allBrandsYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
    }
  `)


  const group = `プレミアム`
	let canonical = CanonicalForOption(CanonicalForShop(location.pathname))

  const sizeStr = ((type_id !== 'wipe') && (type_id !== 'handwipe'))? 'サイズ' : 'タイプ' 
	const typeDescription = getTypeDescription(tempData, type_id)
	const sizeDescription = getSizeDescription(tempData, size_id)
	const brandDescription = brand_id !== 'all' ? getBrandDescription(tempData, brand_id) + ' ' : ''
	let title = `${ShopNameTag(shop_id)}【${group}】${brandDescription}${typeDescription} ${sizeDescription}${sizeStr}`
	let description = `${ShopNameTag(shop_id)}【${group}】${brandDescription}${typeDescription} ${sizeDescription}${sizeStr}で今一番安い${group}おしりふきがすぐ分かります！クーポン割引、ポイント還元、税込、送料込みで1枚あたりの価格を徹底比較！`
	return (
		<Layout>
  	  <Seo title={title} description={description} canonical={canonical}/>
			<ListSearchPremiumWipe typeValue={type_id} sizeValue={size_id} brandValue={brand_id} shopValue={shop_id} articleUrl={`https://hula-la.com${canonical}`} articleTitle={title} />
		</Layout>			
  )
}

export default PremiumWipePage

function getTypeDescription(tempData, cid){
  const ret = tempData.allTypesYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}

function getSizeDescription(tempData, cid){
  const ret = tempData.allSizesYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}

function getBrandDescription(tempData, cid){
  const ret = tempData.allBrandsYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}
