import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import BrandsFilter from "../components/brandsFilter"
import SizesFilter from "../components/sizesFilter"
import TypesFilter from "../components/typesFilter"
import ListSearchDrink from "../components/list"
import {useStaticQuery, graphql } from "gatsby"
import { ShopNameTag, CanonicalForShop, CanonicalForOption} from '../../../utils/common'
import Helmet from "react-helmet"

const IndexDrink = ({ location, pageContext: { type_id, size_id, brand_id, shop_id} }) => {
  const tempData  = useStaticQuery(graphql`
    query IndexDrinkQuery {
      allTypesDrinkYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allSizesDrinkYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allBrandsDrinkYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
    }
  `)


	let canonical = CanonicalForOption(CanonicalForShop(location.pathname))
  const sizeStr = '' 
	const typeDescription = getTypeDescription(tempData, type_id)
	const sizeDescription = getSizeDescription(tempData, size_id)
	const brandDescription = brand_id !== 'all' ? getBrandDescription(tempData, brand_id) + ' ' : ''
	let title = `${ShopNameTag(shop_id)}${brandDescription}${typeDescription} ${sizeDescription}${sizeStr}`
	let description = `${ShopNameTag(shop_id)}${brandDescription}${typeDescription} ${sizeDescription}${sizeStr}で今一番安いドリンクがすぐ分かります！クーポン割引、ポイント還元、税込、送料込みで100mlあたりの価格を徹底比較！`
	return (
		<Layout>
  	  <Seo title={title} description={description} canonical={canonical}/>
			<ListSearchDrink typeValue={type_id} sizeValue={size_id} brandValue={brand_id} shopValue={shop_id} articleUrl={`https://hula-la.com${canonical}`} articleTitle={title} />
			<TypesFilter types={['baby_tea','baby_ion','tea','natural_water']}/>
			<SizesFilter types={['baby_tea','baby_ion','tea','natural_water']}/>
			<BrandsFilter types={['baby_tea','baby_ion','tea','natural_water']}/>
		</Layout>			
  )
}

export default IndexDrink

function getTypeDescription(tempData, cid){
  const ret = tempData.allTypesDrinkYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}

function getSizeDescription(tempData, cid){
  const ret = tempData.allSizesDrinkYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}

function getBrandDescription(tempData, cid){
  const ret = tempData.allBrandsDrinkYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}
