import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FaChevronRight } from "react-icons/fa"

const TypesFilter = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allTypesGarbageYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allSubbrandsGarbageYaml {
        edges {
          node {
            brand_id
            subbrand_id
            description
            types {
              type_id 
              sizes {
                size_id
                gusset	
                size
                color
                num
              }
            }
          }
        }
      }
    }
  `)
  const filterTypesYaml = data.allTypesGarbageYaml.edges.filter(edge => {
    let type_id = edge.node.cid
    return props.types.includes(type_id)
  })

	const category = 'garbage'
  return (
      <>
        <h2 className="title is-5" id="type" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>タイプから探す</h2>
        <div className="columns is-multiline is-gapless">
          {filterTypesYaml.map(edge => {
            const type = edge.node
            return (
              <div className="column is-half is-vcentered">
                <Link to={`/${category}/${type.cid}.html`}>
                  <div className="card">
                    <div className="card-content" style={{paddingTop: `0.8rem`, paddingBottom: `0.8rem`, paddingLeft: `0.5rem`, paddingRight: `0.5rem`,}}>
                      <div className="media">
                        <div className="media-left" style={{margin: `0.0rem`}}>
                          <p className="is-6 has-text-weight-bold">{type.description}</p>
                        </div>
                        <div className="media-content">
                        </div>
                        <div className="media-right" style={{margin: `0.0rem`}}>
                          <span className="icon">
														<FaChevronRight />
													</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </>
   )
}

export default TypesFilter