import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import BrandsFilter from "../components/brandsFilter"
import SizesFilter from "../components/sizesFilter"
import TypesFilter from "../components/typesFilter"
import ListSearchGarbage from "../components/list"
import {useStaticQuery, graphql } from "gatsby"

const IndexGarbage = ({ location, pageContext: { type_id, size_id, brand_id, shop_id} }) => {
  const tempData  = useStaticQuery(graphql`
    query IndexGarbageQuery {
      allTypesGarbageYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allSizesGarbageYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allBrandsGarbageYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
    }
  `)


	let canonical = location.pathname
  const sizeStr = (type_id === 'bag') ? 'サイズ' : '' 
  const typeDescription = getTypeDescription(tempData, type_id)
	const sizeDescription = getSizeDescription(tempData, size_id)
	const brandDescription = brand_id !== 'all' ? getBrandDescription(tempData, brand_id) + ' ' : ''
	let title = `${brandDescription}${typeDescription} ${sizeDescription}${sizeStr}`
	let description = `${brandDescription}${typeDescription} ${sizeDescription}${sizeStr}で今一番安いオムツ処理商品がすぐ分かります！クーポン割引、ポイント還元、税込、送料込みでおむつゴミ袋など1枚あたりの価格を徹底比較！`
	return (
		<Layout>
  	  <Seo title={title} description={description} canonical={canonical}/>
			<ListSearchGarbage typeValue={type_id} sizeValue={size_id} brandValue={brand_id} shopValue={shop_id} articleUrl={`https://hula-la.com${canonical}`} articleTitle={title} />
			<TypesFilter types={['bag','cassette']}/>
			<SizesFilter types={['bag','cassette']}/>
			<BrandsFilter types={['bag','cassette']}/>
		</Layout>			
  )
}

export default IndexGarbage

function getTypeDescription(tempData, cid){
  const ret = tempData.allTypesGarbageYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}

function getSizeDescription(tempData, cid){
  const ret = tempData.allSizesGarbageYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}

function getBrandDescription(tempData, cid){
  const ret = tempData.allBrandsGarbageYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}
