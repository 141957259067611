exports.components = {
  "component---src-categories-daily-templates-brand-page-js": () => import("./../../../src/categories/daily/templates/brandPage.js" /* webpackChunkName: "component---src-categories-daily-templates-brand-page-js" */),
  "component---src-categories-daily-templates-index-js": () => import("./../../../src/categories/daily/templates/index.js" /* webpackChunkName: "component---src-categories-daily-templates-index-js" */),
  "component---src-categories-daily-templates-size-page-js": () => import("./../../../src/categories/daily/templates/sizePage.js" /* webpackChunkName: "component---src-categories-daily-templates-size-page-js" */),
  "component---src-categories-daily-templates-type-page-js": () => import("./../../../src/categories/daily/templates/typePage.js" /* webpackChunkName: "component---src-categories-daily-templates-type-page-js" */),
  "component---src-categories-daily-templates-type-size-brand-page-js": () => import("./../../../src/categories/daily/templates/typeSizeBrandPage.js" /* webpackChunkName: "component---src-categories-daily-templates-type-size-brand-page-js" */),
  "component---src-categories-daily-templates-type-size-brand-subbrand-page-js": () => import("./../../../src/categories/daily/templates/typeSizeBrandSubbrandPage.js" /* webpackChunkName: "component---src-categories-daily-templates-type-size-brand-subbrand-page-js" */),
  "component---src-categories-daily-templates-type-size-page-js": () => import("./../../../src/categories/daily/templates/typeSizePage.js" /* webpackChunkName: "component---src-categories-daily-templates-type-size-page-js" */),
  "component---src-categories-drink-templates-brand-page-js": () => import("./../../../src/categories/drink/templates/brandPage.js" /* webpackChunkName: "component---src-categories-drink-templates-brand-page-js" */),
  "component---src-categories-drink-templates-index-js": () => import("./../../../src/categories/drink/templates/index.js" /* webpackChunkName: "component---src-categories-drink-templates-index-js" */),
  "component---src-categories-drink-templates-size-page-js": () => import("./../../../src/categories/drink/templates/sizePage.js" /* webpackChunkName: "component---src-categories-drink-templates-size-page-js" */),
  "component---src-categories-drink-templates-type-page-js": () => import("./../../../src/categories/drink/templates/typePage.js" /* webpackChunkName: "component---src-categories-drink-templates-type-page-js" */),
  "component---src-categories-drink-templates-type-size-brand-page-js": () => import("./../../../src/categories/drink/templates/typeSizeBrandPage.js" /* webpackChunkName: "component---src-categories-drink-templates-type-size-brand-page-js" */),
  "component---src-categories-drink-templates-type-size-brand-subbrand-page-js": () => import("./../../../src/categories/drink/templates/typeSizeBrandSubbrandPage.js" /* webpackChunkName: "component---src-categories-drink-templates-type-size-brand-subbrand-page-js" */),
  "component---src-categories-drink-templates-type-size-page-js": () => import("./../../../src/categories/drink/templates/typeSizePage.js" /* webpackChunkName: "component---src-categories-drink-templates-type-size-page-js" */),
  "component---src-categories-garbage-templates-brand-page-js": () => import("./../../../src/categories/garbage/templates/brandPage.js" /* webpackChunkName: "component---src-categories-garbage-templates-brand-page-js" */),
  "component---src-categories-garbage-templates-index-js": () => import("./../../../src/categories/garbage/templates/index.js" /* webpackChunkName: "component---src-categories-garbage-templates-index-js" */),
  "component---src-categories-garbage-templates-size-page-js": () => import("./../../../src/categories/garbage/templates/sizePage.js" /* webpackChunkName: "component---src-categories-garbage-templates-size-page-js" */),
  "component---src-categories-garbage-templates-type-page-js": () => import("./../../../src/categories/garbage/templates/typePage.js" /* webpackChunkName: "component---src-categories-garbage-templates-type-page-js" */),
  "component---src-categories-garbage-templates-type-size-brand-page-js": () => import("./../../../src/categories/garbage/templates/typeSizeBrandPage.js" /* webpackChunkName: "component---src-categories-garbage-templates-type-size-brand-page-js" */),
  "component---src-categories-garbage-templates-type-size-brand-subbrand-page-js": () => import("./../../../src/categories/garbage/templates/typeSizeBrandSubbrandPage.js" /* webpackChunkName: "component---src-categories-garbage-templates-type-size-brand-subbrand-page-js" */),
  "component---src-categories-garbage-templates-type-size-page-js": () => import("./../../../src/categories/garbage/templates/typeSizePage.js" /* webpackChunkName: "component---src-categories-garbage-templates-type-size-page-js" */),
  "component---src-categories-milk-templates-brand-page-js": () => import("./../../../src/categories/milk/templates/brandPage.js" /* webpackChunkName: "component---src-categories-milk-templates-brand-page-js" */),
  "component---src-categories-milk-templates-index-js": () => import("./../../../src/categories/milk/templates/index.js" /* webpackChunkName: "component---src-categories-milk-templates-index-js" */),
  "component---src-categories-milk-templates-size-page-js": () => import("./../../../src/categories/milk/templates/sizePage.js" /* webpackChunkName: "component---src-categories-milk-templates-size-page-js" */),
  "component---src-categories-milk-templates-type-page-js": () => import("./../../../src/categories/milk/templates/typePage.js" /* webpackChunkName: "component---src-categories-milk-templates-type-page-js" */),
  "component---src-categories-milk-templates-type-size-brand-page-js": () => import("./../../../src/categories/milk/templates/typeSizeBrandPage.js" /* webpackChunkName: "component---src-categories-milk-templates-type-size-brand-page-js" */),
  "component---src-categories-milk-templates-type-size-brand-subbrand-page-js": () => import("./../../../src/categories/milk/templates/typeSizeBrandSubbrandPage.js" /* webpackChunkName: "component---src-categories-milk-templates-type-size-brand-subbrand-page-js" */),
  "component---src-categories-milk-templates-type-size-page-js": () => import("./../../../src/categories/milk/templates/typeSizePage.js" /* webpackChunkName: "component---src-categories-milk-templates-type-size-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-all-campaign-js": () => import("./../../../src/templates/allCampaign.js" /* webpackChunkName: "component---src-templates-all-campaign-js" */),
  "component---src-templates-brand-page-js": () => import("./../../../src/templates/brandPage.js" /* webpackChunkName: "component---src-templates-brand-page-js" */),
  "component---src-templates-index-diaper-js": () => import("./../../../src/templates/indexDiaper.js" /* webpackChunkName: "component---src-templates-index-diaper-js" */),
  "component---src-templates-index-wipe-js": () => import("./../../../src/templates/indexWipe.js" /* webpackChunkName: "component---src-templates-index-wipe-js" */),
  "component---src-templates-keyword-page-js": () => import("./../../../src/templates/keywordPage.js" /* webpackChunkName: "component---src-templates-keyword-page-js" */),
  "component---src-templates-longtime-diaper-js": () => import("./../../../src/templates/longtimeDiaper.js" /* webpackChunkName: "component---src-templates-longtime-diaper-js" */),
  "component---src-templates-premium-diaper-js": () => import("./../../../src/templates/premiumDiaper.js" /* webpackChunkName: "component---src-templates-premium-diaper-js" */),
  "component---src-templates-premium-wipe-js": () => import("./../../../src/templates/premiumWipe.js" /* webpackChunkName: "component---src-templates-premium-wipe-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */),
  "component---src-templates-size-page-js": () => import("./../../../src/templates/sizePage.js" /* webpackChunkName: "component---src-templates-size-page-js" */),
  "component---src-templates-subbrand-page-js": () => import("./../../../src/templates/subbrandPage.js" /* webpackChunkName: "component---src-templates-subbrand-page-js" */),
  "component---src-templates-tabel-diaper-brand-js": () => import("./../../../src/templates/tabelDiaperBrand.js" /* webpackChunkName: "component---src-templates-tabel-diaper-brand-js" */),
  "component---src-templates-tabel-diaper-campaign-brand-js": () => import("./../../../src/templates/tabelDiaperCampaignBrand.js" /* webpackChunkName: "component---src-templates-tabel-diaper-campaign-brand-js" */),
  "component---src-templates-tabel-diaper-campaign-js": () => import("./../../../src/templates/tabelDiaperCampaign.js" /* webpackChunkName: "component---src-templates-tabel-diaper-campaign-js" */),
  "component---src-templates-tabel-diaper-js": () => import("./../../../src/templates/tabelDiaper.js" /* webpackChunkName: "component---src-templates-tabel-diaper-js" */),
  "component---src-templates-tabel-diaper-size-js": () => import("./../../../src/templates/tabelDiaperSize.js" /* webpackChunkName: "component---src-templates-tabel-diaper-size-js" */),
  "component---src-templates-tabel-diaper-type-js": () => import("./../../../src/templates/tabelDiaperType.js" /* webpackChunkName: "component---src-templates-tabel-diaper-type-js" */),
  "component---src-templates-tabel-long-diaper-js": () => import("./../../../src/templates/tabelLongDiaper.js" /* webpackChunkName: "component---src-templates-tabel-long-diaper-js" */),
  "component---src-templates-tabel-milk-js": () => import("./../../../src/templates/tabelMilk.js" /* webpackChunkName: "component---src-templates-tabel-milk-js" */),
  "component---src-templates-tabel-premium-diaper-js": () => import("./../../../src/templates/tabelPremiumDiaper.js" /* webpackChunkName: "component---src-templates-tabel-premium-diaper-js" */),
  "component---src-templates-tabel-toitore-diaper-js": () => import("./../../../src/templates/tabelToitoreDiaper.js" /* webpackChunkName: "component---src-templates-tabel-toitore-diaper-js" */),
  "component---src-templates-tabel-wipe-js": () => import("./../../../src/templates/tabelWipe.js" /* webpackChunkName: "component---src-templates-tabel-wipe-js" */),
  "component---src-templates-toitore-diaper-js": () => import("./../../../src/templates/toitoreDiaper.js" /* webpackChunkName: "component---src-templates-toitore-diaper-js" */),
  "component---src-templates-type-page-js": () => import("./../../../src/templates/typePage.js" /* webpackChunkName: "component---src-templates-type-page-js" */),
  "component---src-templates-type-size-brand-page-js": () => import("./../../../src/templates/typeSizeBrandPage.js" /* webpackChunkName: "component---src-templates-type-size-brand-page-js" */),
  "component---src-templates-type-size-brand-subbrand-page-js": () => import("./../../../src/templates/typeSizeBrandSubbrandPage.js" /* webpackChunkName: "component---src-templates-type-size-brand-subbrand-page-js" */),
  "component---src-templates-type-size-page-js": () => import("./../../../src/templates/typeSizePage.js" /* webpackChunkName: "component---src-templates-type-size-page-js" */)
}

