import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Image from "../../../components/image"
import ImageForTwitter from "../../../components/imageForTwitter"
import Review from "../../../components/review"
import Share from "../../../components/share"
import TweetWidget from "../../../components/tweetWidget"
import moment from 'moment'
import Campaign from "../../../components/campaign"
import Tag from "../../../components/tag"
import { IoListCircleOutline } from "react-icons/io5";

const ListSearchAllGarbageResult = props  => {
  // itemListからtxtがあるものだけを抽出し、allContentfulItems形で格納する。itemList[0]にばらす感じ。
  var allDiscountItems = {}
  allDiscountItems.edges = []

  for (var items of props.tempData.allContentfulItems.edges)
  {
    for (var item of items.node.itemList){
      var discountItem = {}
      discountItem.node = {
        brandId:items.node.brandId,
        sizeId:items.node.sizeId,
        subbrandId:items.node.subbrandId,
        typeId:items.node.typeId,
      }
      discountItem.node.itemList = []
      discountItem.node.itemList.push(item)
      allDiscountItems.edges.push(discountItem)
    }
  }

  // allDiscountItems.edgesを1枚当たりの価格が安い順に並び替える
  allDiscountItems.edges.sort(function(a,b){
    return a.node.itemList[0].per - b.node.itemList[0].per
  })
  const filterItems = allDiscountItems.edges.filter(function(tempItem){
    if(props.brandValue === 'all'){
      return (tempItem.node.typeId === props.typeValue) && (tempItem.node.sizeId === props.sizeValue)
    }
    else{
      return (tempItem.node.typeId === props.typeValue) && (tempItem.node.sizeId === props.sizeValue) && (tempItem.node.brandId === props.brandValue)
    }
  })
  const result = filterItems.slice(0,10)

  let cnt = 0
  let cheap_item0 = {}
  return (
    <div className="columns is-multiline is-gapless">
      {result.map(item => {
        const type = getType(props.tempData, item.node.typeId)
        const size = getSize(props.tempData, item.node.sizeId)
        const brand = getBrand(props.tempData, item.node.brandId)
        const subbrand = getSubbrand(props.tempData, item.node.brandId, item.node.subbrandId)
        const filename = `${brand.cid}_${type.cid}_${size.cid}_${subbrand.subbrand_id}.jpg`
        const detailurl = `/garbage/${type.cid}_${size.cid}_${brand.cid}_${subbrand.subbrand_id}.html`
        const sizeStr = '' 
        const itemStr = 'オムツ処理'
        const item0 = item.node.itemList[0]
        const subbrandDetail = subbrand.types.find(n => {
          return n.type_id === type.cid
        }).sizes.find(n => {
          return n.size_id === size.cid
        })
        const bag_size = `${size.description}サイズ（${subbrandDetail.size}）`

        const sendPrice = item0.sendPrice > 0 ? `送料${item0.sendPrice}円` : item0.sendPrice === 0 ? `送料無料` : item0.sendPrice === -1 ? `実店舗` : '店舗受取';
        cnt += 1
        if(cnt === 1){
          cheap_item0 = item0
        }
        const order = cnt
        let price_org = ``
        let unit = ''
        if(type.cid === "bag"){
          unit = '枚'
        }
        else if(type.cid === "cassette"){
          unit = '個'
        }
        if(item0.txt){
          if(item0.txt.match(/OFF/))
          {
            price_org = `${PriceKanma(item0.price_org)}円`
          }
        }
        return (
          <div className="column is-half">
            <a href={`${item0.href}`} rel="nofollow"
              onClick={e => {
                trackCustomEvent({
                  category: `${type.cid}_${size.cid}_${brand.cid}_${subbrand.subbrand_id}`,
                  action: `${item0.shop}`,
                  label: `TopPage${order}位`,
                  transport: 'beacon'
                })
              }}									 
            >
              <div className="card">
                <div className="card-content" style={{paddingTop: `0.8rem`, paddingBottom: `0.8rem`, paddingLeft: `0.5rem`, paddingRight: `0.5rem`,}}>
                  <div className="media">
                    <div className="media-left" style={{margin: `0.0rem`}}>
                      { cnt !== 0 && (
                        <span><strong>&nbsp;&nbsp;&nbsp;&nbsp;{order}<span style={{fontSize:`0.9rem`}}>位</span></strong></span>
			                )}
                      <Image filename={filename} alt={`${brand.description}${subbrand.description}${type.description}${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
                      { cnt === 1 && (
                        <ImageForTwitter filename={filename} alt={`${brand.description}${subbrand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
			                )}
                      { cnt === 1 && (
					              <span class="has-text-danger" style={{fontSize:`0.8rem`}}>&nbsp;&nbsp;&nbsp;<strong>最安値</strong></span>
			                )}
                      { cnt === 1 && (
                        <div class="has-text-centered" style={{paddingTop:`0.95rem`, fontSize: `32px`}}>
                        <Link to={detailurl}><span className="icon"><IoListCircleOutline style={{color: `#EB9DA8`}} /></span></Link>
                        </div>
			                )}
                      { cnt !== 1 && (
                        <div class="has-text-centered" style={{paddingTop:`0.72rem`, fontSize: `32px`}}>
                        <Link to={detailurl}><span className="icon"><IoListCircleOutline style={{color: `#EB9DA8`}} /></span></Link>
                        </div>
			                )}
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p style={{marginBottom: `0.2rem`}}>
                          <span style={{fontSize:`0.7rem`}}><strong>{brand.description} {subbrand.description}</strong></span>
                          <br />
                          <span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item0.per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/{unit}</span></span>
                          <span style={{color: `#ff0000`}} className="is-size-7">&nbsp;&nbsp;{PriceKanma(item0.price)}円<span style={{color: `#808080`}}>&nbsp;<strike>{price_org}</strike></span></span>&nbsp;
                          <Tag item={item0} />
                          <br />
                          { 'point' in item0 && item0.point > 0 && (
                            <><span className="tag is-danger is-light" style={{fontSize:`0.6rem`}}><b>{item0.point}ポイント</b></span>&nbsp;</>
                          )}
                          <span className="tag" style={{fontSize:`0.6rem`}}>{sendPrice}</span>&nbsp;
                          { type.cid === "bag" && (
                            <>
                              <span className="tag" style={{fontSize:`0.6rem`}}>{item0.num}枚入</span>&nbsp;
                              <span className="tag" style={{fontSize:`0.6rem`}}>{bag_size}</span>&nbsp;
                            </>
                          )}                          
                          { type.cid === "cassette" && (
                            <>
                              <span className="tag" style={{fontSize:`0.6rem`}}>{item0.num}個</span>&nbsp;
                            </>
                          )}                          
                          <br />
                          <span style={{color: `#0783c2`}} className="is-size-7">&nbsp;&nbsp;{item0.shop}</span>
                          <Review item={item0} category={`${type.cid}_${size.cid}_${brand.cid}_${subbrand.subbrand_id}`}/>
                        </p>
                        <Campaign item={item0} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            { cnt === 1 && (
              <TweetWidget urlPath={`${props.articleUrl}`} />				
            )}
            { cnt === 10 && (
              <Share articleUrl={`${props.articleUrl}`} articleTitle={`【${cheap_item0.per.toFixed(1)}円/${unit}】${props.articleTitle}`} />					
            )}
          </div>
        )
      })}
    </div>
  )
}

const ListSearchAllGarbage = props => {
	// 更新日付をGraphQLから直接表示するとUTCとなるため、自前のmoment.jsで表示
	const update = moment().format('YYYY年M月D日')
  const tempData  = useStaticQuery(graphql`
    query ListSearchAllGarbageQuery {
      allContentfulItems {
        edges {
          node {
            brandId
            sizeId
            subbrandId
            typeId
            itemList {
              num
              href
              shopId
              shop
              per
              per_org
              price
              price_org
              sendPrice
              souryou
              txt
              point
              star
              review
              reviewUrl
            }
          }
        }
      }
      allTypesGarbageYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allSizesGarbageYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allBrandsGarbageYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allShopsYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allSubbrandsGarbageYaml {
        edges {
          node {
            brand_id
            subbrand_id
            description
            types {
              type_id 
              sizes {
                size_id
                gusset	
                size
                color
                num
              }
            }
          }
        }
      }  
    }
  `)
  return (
      <>
        <h2 className="title is-6" id="discount" style={{marginTop: `0.5rem`, marginLeft: `0.5rem`, marginBottom: `0.5rem`}}>オムツ処理 ランキング Top10<small> ({update}更新)</small></h2>
        <p style={{fontSize:`0.8rem`, color: `#808080`}}><small>&nbsp;&nbsp;&nbsp;<strong>割引後にポイントと送料を含めた実質価格で</strong>単価あたりを計算！</small></p>
				<p style={{fontSize:`0.8rem`, color: `#808080`, marginBottom: `0.5rem`}}><small>&nbsp;&nbsp;&nbsp;（本ページのリンクには広告が含まれています）</small></p>
        <div class="tabs is-toggle is-small is-fullwidth" style={{marginBottom: `0.1rem`, marginLeft: `0.1rem`, marginRight: `0.1rem`, fontSize:`0.5rem`}}>
          <li class={TypeActive(props, 'bag')}><Link to={TypeLink(tempData, props, 'bag')}>おむつゴミ袋</Link></li>
          <li class={TypeActive(props, 'cassette')}><Link to={TypeLink(tempData, props, 'cassette')}>おむつ処理ポットスペアカセット(カートリッジ)</Link></li>
        </div>
        <div class="tabs is-toggle is-small is-fullwidth" style={{marginBottom: `0.1rem`, marginLeft: `0.1rem`, marginRight: `0.1rem`,fontSize:`0.5rem`}}>
          <ul>
            { SizeExist(tempData, props.typeValue, 'garbage_ss') && (
              <li class={SizeActive(props, 'garbage_ss')}><Link to={SizeLink(tempData, props, 'garbage_ss')}>　SS　</Link></li>
            )}
            { SizeExist(tempData, props.typeValue, 'garbage_s') && (
              <li class={SizeActive(props, 'garbage_s')}><Link to={SizeLink(tempData, props, 'garbage_s')}>　S　</Link></li>
            )}
            { SizeExist(tempData, props.typeValue, 'garbage_m') && (
              <li class={SizeActive(props, 'garbage_m')}><Link to={SizeLink(tempData, props, 'garbage_m')}>　M　</Link></li>
            )}
            { SizeExist(tempData, props.typeValue, 'garbage_l') && (
              <li class={SizeActive(props, 'garbage_l')}><Link to={SizeLink(tempData, props, 'garbage_l')}>　L　</Link></li>
            )}
            { SizeExist(tempData, props.typeValue, 'garbage_ll') && (
              <li class={SizeActive(props, 'garbage_ll')}><Link to={SizeLink(tempData, props, 'garbage_ll')}> LL </Link></li>
            )}
            { SizeExist(tempData, props.typeValue, 'garbage_long') && (
              <li class={SizeActive(props, 'garbage_long')}><Link to={SizeLink(tempData, props, 'garbage_long')}>ロング</Link></li>
            )}
            { SizeExist(tempData, props.typeValue, 'poitech') && (
              <li class={SizeActive(props, 'poitech')}><Link to={SizeLink(tempData, props, 'poitech')}>ポイテック用</Link></li>
            )}
            { SizeExist(tempData, props.typeValue, 'smartpoi') && (
              <li class={SizeActive(props, 'smartpoi')}><Link to={SizeLink(tempData, props, 'smartpoi')}>スマートポイ用</Link></li>
            )}
            { SizeExist(tempData, props.typeValue, 'nioipoi') && (
              <li class={SizeActive(props, 'nioipoi')}><Link to={SizeLink(tempData, props, 'nioipoi')}>ニオイポイ用</Link></li>
            )}
            { SizeExist(tempData, props.typeValue, 'niowanakutepoi') && (
              <li class={SizeActive(props, 'niowanakutepoi')}><Link to={SizeLink(tempData, props, 'niowanakutepoi')}>におわなくてポイ用</Link></li>
            )}
          </ul>
        </div>
        <div class="tabs is-toggle is-small is-fullwidth" style={{marginBottom: `0.1rem`, marginLeft: `0.1rem`, marginRight: `0.1rem`,fontSize:`0.5rem`}}>
          <ul>
            <li class={BrandActive(props, 'all')}><Link to={BrandLink(tempData, props, 'all')}>すべて</Link></li>
            { BrandExist(tempData, props.typeValue, props.sizeValue, 'bos') && (
              <li class={BrandActive(props, 'bos')}><Link to={BrandLink(tempData, props, 'bos')}>BOS</Link></li>
            )}
            { BrandExist(tempData, props.typeValue, props.sizeValue, 'aprica') && (
              <li class={BrandActive(props, 'aprica')}><Link to={BrandLink(tempData, props, 'aprica')}>アップリカ</Link></li>
            )}
            { BrandExist(tempData, props.typeValue, props.sizeValue, 'onestep') && (
              <li class={BrandActive(props, 'onestep')}><Link to={BrandLink(tempData, props, 'onestep')}>ワンステップ</Link></li>
            )}
            { BrandExist(tempData, props.typeValue, props.sizeValue, 'amethyst') && (
              <li class={BrandActive(props, 'amethyst')}><Link to={BrandLink(tempData, props, 'amethyst')}>アメジスト</Link></li>
            )}
            { BrandExist(tempData, props.typeValue, props.sizeValue, 'combi') && (
              <li class={BrandActive(props, 'combi')}><Link to={BrandLink(tempData, props, 'combi')}>コンビ</Link></li>
            )}
          </ul>
        </div>
        <ListSearchAllGarbageResult typeValue={props.typeValue} sizeValue={props.sizeValue} brandValue={props.brandValue} shopValue={props.shopValue} articleUrl={props.articleUrl} articleTitle={props.articleTitle} tempData={tempData}/>
      </>
  )
}
export default ListSearchAllGarbage

function BrandActive(props, brand_id){
  let ret = ""
  if(props.brandValue === brand_id){
    ret = "is-active"
  }
  return ret
}

function BrandLink(tempData, props, brand_id){
  let href = '/garbage/' + props.typeValue + '_' + props.sizeValue + '_' + brand_id + '_' + props.shopValue
  return href
}

function BrandExist(tempData, type_id, size_id, brand_id){
  let ret = false
  for(let edge of tempData.allSubbrandsGarbageYaml.edges){
    if(edge.node.brand_id === brand_id){
      for(let type of edge.node.types){
        if(type.type_id === type_id)
        {
          for(let size of type.sizes){
            if(size.size_id === size_id){
              ret = true
            }
          }  
        }
      }  
    }
  }
  return ret
}

function TypeActive(props, type_id){
  let ret = ""
  if(props.typeValue === type_id){
    ret = "is-active"
  }
  return ret
}

function TypeLink(tempData, props, type_id){
  let sizeValue = props.sizeValue
  let brandValue = props.brandValue
  if(!SizeExist(tempData, type_id, props.sizeValue)){
    sizeValue = getFirstSize(tempData, type_id)
  }
  if(!BrandExist(tempData, type_id, sizeValue, brandValue)){
    brandValue = 'all'
  }
  return '/garbage/' + type_id + '_' + sizeValue + '_' + brandValue + '_' + props.shopValue
}

function SizeActive(props, size_id){
  let ret = ""
  if(props.sizeValue === size_id){
    ret = "is-active"
  }
  return ret
}

function SizeLink(tempData, props, size_id){
  let href = '/garbage/' + props.typeValue + '_' + size_id + '_' + props.brandValue + '_' + props.shopValue
  if(!BrandExist(tempData, props.typeValue, size_id, props.brandValue)){
    href = '/garbage/' + props.typeValue + '_' + size_id + '_all_' + props.shopValue
  }
  return href
}

function SizeExist(tempData, type_id, size_id){
  let ret = false
  for(let edge of tempData.allSubbrandsGarbageYaml.edges){
    for(let type of edge.node.types){
      if(type.type_id === type_id)
      {
        for(let size of type.sizes){
          if(size.size_id === size_id){
            ret = true
          }
        }  
      }
    }
  }
  return ret
}

function getFirstSize(tempData, type_id){
  let ret = ''
  for(let sizes of tempData.allSizesGarbageYaml.edges){
    if((sizes.node.cid === 'sss') || (sizes.node.cid === 'bbb')){
      continue
    }
    for(let edge of tempData.allSubbrandsGarbageYaml.edges){
      for(let type of edge.node.types){
        if(type.type_id === type_id)
        {
          for(let size of type.sizes){
            if(size.size_id === sizes.node.cid){
              ret = size.size_id
              break
            }
          }  
        }
        if(ret !== ''){break}
      }
      if(ret !== ''){break}
    }
    if(ret !== ''){break}
  }
  return ret
}

function getType(tempData, cid){
  const ret = tempData.allTypesGarbageYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node
}

function getSize(tempData, cid){
  const ret = tempData.allSizesGarbageYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node
}

function getBrand(tempData, cid){
  const ret = tempData.allBrandsGarbageYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node
}

function getSubbrand(tempData, cid, subid){
  const ret = tempData.allSubbrandsGarbageYaml.edges.find(edge => {
    return ( edge.node.brand_id === cid ) && ( edge.node.subbrand_id === subid )
  })
  return ret.node
}

function PriceKanma(_price){
	var price = _price.toString(10);
	var length = price.length;
	var price_kanma;
	if(length > 3)
	{
		price_kanma = price.slice(0,length-3) + "," + price.slice(length-3);
	}
	else
	{
		price_kanma = price;
	}
	return price_kanma;
}