import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import BrandsFilter from "../components/brandsFilter"
import SizesFilter from "../components/sizesFilter"
import TypesFilter from "../components/typesFilter"
import ListSearchMilk from "../components/list"
import {useStaticQuery, graphql } from "gatsby"
import { ShopNameTag, CanonicalForShop, CanonicalForOption} from '../../../utils/common'
import Helmet from "react-helmet"

const IndexMilk = ({ location, pageContext: { type_id, size_id, brand_id, shop_id} }) => {
  const tempData  = useStaticQuery(graphql`
    query IndexMilkQuery {
      allTypesMilkYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allSizesMilkYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
      allBrandsMilkYaml {
        edges {
          node {
            cid
            description
          }
        }
      }
    }
  `)


	let canonical = CanonicalForOption(CanonicalForShop(location.pathname))
  const sizeStr = '' 
	const typeDescription = getTypeDescription(tempData, type_id)
	const sizeDescription = getSizeDescription(tempData, size_id)
	const brandDescription = brand_id !== 'all' ? getBrandDescription(tempData, brand_id) + ' ' : ''
	let title = `${ShopNameTag(shop_id)}${brandDescription}${typeDescription} ${sizeDescription}${sizeStr}`
	let description = `${ShopNameTag(shop_id)}${brandDescription}${typeDescription} ${sizeDescription}${sizeStr}で今一番安いミルクがすぐ分かります！クーポン割引、ポイント還元、税込、送料込みで100mlあたりの価格を徹底比較！`
	return (
		<Layout>
  	  <Seo title={title} description={description} canonical={canonical}/>
			<ListSearchMilk typeValue={type_id} sizeValue={size_id} brandValue={brand_id} shopValue={shop_id} articleUrl={`https://hula-la.com${canonical}`} articleTitle={title} />
			<TypesFilter types={['milk_powder','milk_liquid']}/>
			<SizesFilter types={['milk_powder','milk_liquid']}/>
			<BrandsFilter types={['milk_powder','milk_liquid']}/>
		</Layout>			
  )
}

export default IndexMilk

function getTypeDescription(tempData, cid){
  const ret = tempData.allTypesMilkYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}

function getSizeDescription(tempData, cid){
  const ret = tempData.allSizesMilkYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}

function getBrandDescription(tempData, cid){
  const ret = tempData.allBrandsMilkYaml.edges.find(edge => {
    return ( edge.node.cid === cid )
  })
  return ret.node.description
}
